import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import ErrorWrapper from "./ErrorWrapper";

function Error() {
  return (
    <>
      <Breadcrumb name="Error" />
      <ErrorWrapper />
    </>
  );
}

export default Error;
